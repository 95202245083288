<template>
  <div>
    <div class="nurseOffice mt10">
      <div class="mt10 mb-3">
        <el-breadcrumb class="mt-3" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">{{ $t('message.hospital') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t('message.schedule_cabinet') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-loading="loadingData">
        <filterSchedules ref="filter" />
        <button
            v-for="(item, index) in items"
            @click="$refs.schedule.statisticFilter(item, index)"
            class="filter-wrap__btn"
            :class="{ active: index === activeFilterIndex }"
            :key="index"
        >
          {{ $t(`message.${index}`) }}
        </button>
        <el-select
          v-model="type_id"
          :placeholder="$t(`message.patient_history_type`)"
          clearable
        >
          <el-option
            v-for="type in types"
            :key="type.id"
            :value="type.id"
            :label="type.name"
          ></el-option>
        </el-select>
        <schedulesCabinetFull @loadOn="loadOn()" @loadOff="loadOff()" ref="schedule" />
      </div>
    </div>
  </div>
</template>
<script>
import schedulesCabinetFull from "./componentsReadOnly/schedulesCabinetFull";
import filterSchedules from "./componentsReadOnly/filter-schedules";
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "schedulesCabinet",
  components: { schedulesCabinetFull, filterSchedules },
  data() {
    return {
      loadingData: false,
      activeFilterIndex: "Day",
      activeItem: null,
      type_id: null,
      items: {
        "Day": [
          { groupBy: "Month" },
          { groupBy: "Day", format: "d" },
        ],
        "Hour": [
          { groupBy: "Month" },
          { groupBy: "Day", format: "d" },
          { groupBy: "Hour" }
        ]
      },
    }
  },
  watch: {
    type_id: {
      handler: async function (val) {
        await this.$refs.schedule.updateDataroom({patient_history_type: this.type_id});
        let type = this.types.find((item) => item.id === val);
        this.$refs.schedule.updateBusinessHours(type);
      }
    }
  },
  mounted() {
    this.loadTypes()
    this.$refs.schedule.scroll()
    this.$refs.schedule.fetchAgain();
  },
  computed: {
    ...mapGetters({
      bunk: 'schedulesCabinets/bunk',
      types: "patientHistoryTypes/list",
    })
  },
  methods: {
    ...mapActions({
      empty: 'schedulesCabinets/empty',
      delete: 'schedulesCabinets/destroy',
      loadTypes: "patientHistoryTypes/index",
    }),
    afterOpen() {
    },
    loadOn() {
      this.loadingData = true
    },
    loadOff() {
      this.loadingData = false
    },
    afterClosed() {
    },
  },

};
</script>
<style lang="scss" scoped>
.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
</style>